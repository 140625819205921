
<nz-tabset>
  <nz-tab nzTitle="General Messages">
      
    <nz-table #nzTable [nzData]="notifications" nzTableLayout="fixed">
    <thead>
      <tr>
        <th nzWidth="60px"></th>
        <th>ID</th>
        <th>Name</th>
        <th>Date</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let notification of notifications">
        <tr>
          <td [nzExpand]="expandSet.has(notification.id)" (nzExpandChange)="onExpandChangeNotification(notification, $event)"></td>
          <td>{{ notification.id }}</td>
          <td>{{ notification.name }}</td>
          <td>{{ notification.datetime }}</td>
          <td><nz-badge nzStatus="{{ notification.status? 'success' : 'error' }}"></nz-badge>{{ notification.status? "Actived" : "Unactived" }}</td>
        </tr>
        <tr [nzExpand]="expandSet.has(notification.id)">
          <div nz-row>
            <div nz-col nzSpan="6">
    
              <!-- ID -->
              <label for="id">ID</label>
              <br>
              <input nz-input placeholder="ID" id="id" [(ngModel)]="notification.id" [disabled]="true" />
              
              <br>
              
              <!-- Name -->
              <label for="name">Name</label>
              <br>
              <input nz-input placeholder="Name" id="name" [(ngModel)]="notification.name" />
              
              <br>
              
              <!-- Message -->
              <label for="message">Meesage</label>
              <br>
              <textarea nz-input placeholder="Message" id="message" [(ngModel)]="notification.message"></textarea>
              <br>
    
              <!-- Datetime -->
              <label for="datetime">Time to engage</label>
              <br>
              <nz-date-picker [nzShowTime]="true" (ngModelChange)="transformDateOut($event,notification)" [ngModel]="getDate(notification)"></nz-date-picker>
              <br>
              <span>Use your local time. The date will be automatically saved in UTC format. </span>
              <!-- status -->
              <br>
              <br>
              <label for="status">Status Active/Inactive</label>
              <br>
              <nz-switch [(ngModel)]="notification.status"></nz-switch>
              
          
              
            </div>
            <div nz-col nzSpan="6">
              <!-- Image -->
              <div nz-row>
                <div nz-col nzSpan="12">
                  <nz-upload class="avatar-uploader" [nzAction]="api('notification/upload/')" nzName="image" nzListType="picture-card" [nzShowUploadList]="false" (nzChange)="upload($event,notification)">
                  <ng-container *ngIf="!notification.image">
                    <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                    <div class="ant-upload-text">Upload</div>
                  </ng-container>
                    <img *ngIf="notification.image" [src]="notification.image" style="width: 100%" />
                  </nz-upload>
                  
                </div>
                <div nz-col nzSpan="12">
                  <label for="image">Drag and drop to upload</label>
                  <br>
                  <br>
                  <ul>
                    <li>Max size 500x500</li>
                    <li>Max file size 500kb</li>
                  </ul>
                  <input nz-input placeholder="Link to image" id="image" [(ngModel)]="notification.image" /> 
                  <br>
                </div>
              </div>
              <br>
              <!-- Link -->
              <label for="link">Link</label>
              <br>
              <input nz-input placeholder="Link to redirect notification" id="link" [(ngModel)]="notification.link" />
              

              <br>
              
              <!-- Segmentation? -->
              <br>
    
              <label nz-checkbox [(ngModel)]="notification.segment">Segment message</label>
              <br>
              <br>
              
             
              <div [hidden]="!notification.segment">
                <nz-select  nzMode="multiple" [(ngModel)]="notification.segments_id">
                    <nz-option *ngFor="let item of segments" [nzValue]="item.id" nzLabel="{{ item.name }}"></nz-option>
                </nz-select>
              </div>
              
        
              
              
              
            </div>
            <div nz-col nzSpan="12" style="text-align: right;">
              <nz-divider nzDashed nzText="Example as how notification will look"></nz-divider>
              <br>
              <!-- Exmple notification -->
              <nz-card nzHoverable style="width:350px; height: 150px; margin: 0 auto; text-align: left;" [nzCover]="coverTemplate">
                <nz-card-meta  nzTitle="{{ notification.name }}" nzDescription="{{ notification.message }}"></nz-card-meta>
              </nz-card>
              <ng-template #coverTemplate>
                <img alt="example" style="width: 80px;height: 80px;float: left;position: absolute;top: 35px;left: 10px;" src="https://img.onesignal.com/permanent/51ce51fb-dab3-43a3-ab52-6e45cb5dfd68.png" />
              </ng-template>
              
              <br>
              <nz-divider nzDashed ></nz-divider>
              <br>
    
              <button nz-button nzType="default" (click)="save(notification,0)"><i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i> Save</button>
              <button  *ngIf="notification.new" nz-button nzType="default" (click)="cancel(notification, notifications)"><i nz-icon nzType="close-circle" nzTheme="twotone"></i> Cancel</button>
              <button *ngIf="!notification.new" nz-button nzType="default" (click)="deleteNotification(notification)"><i [nzTwotoneColor]="'#eb2f96'" nz-icon nzType="delete" nzTheme="twotone"></i> Delete</button>
              <button nz-button nzType="default" (click)="sendNotification(notification)"><i nz-icon nzType="rocket" nzTheme="twotone"></i> Send</button>
              
            </div>
          </div>
        </tr>
      </ng-container>
    </tbody>
    </nz-table>
    <br>
    <br>
  <button nz-button nzType="default"  (click)="createNotification()"><i nz-icon nzType="plus-circle" nzTheme="twotone"></i> Create</button>

  </nz-tab>
  <nz-tab nzTitle="Automated Messages">
    <nz-table #nzTable [nzData]="segments" nzTableLayout="fixed">
      <thead>
        <tr>
          <th nzWidth="60px"></th>
          <th>ID</th>
          <th>Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let automated of automateds">
          <tr>
            <td [nzExpand]="expandSet.has(automated.id)" (nzExpandChange)="onExpandChangeNotification(automated, $event)"></td>
            <td>{{ automated.id }}</td>
            <td>{{ automated.name }}</td>
            <td><nz-badge nzStatus="{{ automated.status? 'success' : 'error' }}"></nz-badge>{{ automated.status? "Actived" : "Unactived" }}</td>
          </tr>
          <tr [nzExpand]="expandSet.has(automated.id)">
            <div nz-row [nzGutter]="16">
                <div nz-col nzSpan="6">
        
                  <!-- ID -->
                  <label for="id">ID</label>
                  <br>
                  <input nz-input placeholder="ID" id="id" [(ngModel)]="automated.id" [disabled]="true" />
                  
                  <br>
                  
                  <!-- Name -->
                  <label for="name">Name</label>
                  <br>
                  <input nz-input placeholder="Name" id="name" [(ngModel)]="automated.name" />
                  
                  <br>
                  
                  <!-- Message -->
                  <label for="message">Meesage</label>
                  <br>
                  <textarea nz-input placeholder="Message" id="message" [(ngModel)]="automated.message"></textarea>
                  <br>
                  <br>
                  
                  <label for="status">Status Active/Inactive</label>
                  <br>
                  <nz-switch [(ngModel)]="automated.status"></nz-switch>
                  
                  <br>
                  <br>
        
                  <!-- Datetime -->
                  <!--

                    <label for="datetime">Time to engage</label>
                    <br>
                    <nz-date-picker [nzShowTime]="true" [(ngModel)]="automated.datetime"></nz-date-picker>
                    
                    <br>
                  -->
                  
              
                  
                </div>
                <div nz-col nzSpan="6">
                  <!-- Image -->
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <nz-upload class="avatar-uploader" [nzAction]="api('notification/upload/')" nzName="image" nzListType="picture-card" [nzShowUploadList]="false" (nzChange)="upload($event,automated)">
                      <ng-container>
                        <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                        <div class="ant-upload-text">Upload</div>
                      </ng-container>
        
                      </nz-upload>
                      
                    </div>
                    <div nz-col nzSpan="12">
                      <label for="image">Drag and drop to upload</label>
                      <br>
                      <br>
                      <ul>
                        <li>Max size 500x500</li>
                        <li>Max file size 500kb</li>
                      </ul>
                      <input nz-input placeholder="Code" id="image" [(ngModel)]="automated.image" /> 
                      <br>
                    </div>
                  </div>
                  <br>
                  
                  <!-- Link -->
                  <label for="link">Link</label>
                  <br>
                  <input nz-input placeholder="Code" id="link" [(ngModel)]="automated.link" />
                  
                  <br>
                  <br>
                  <!-- Code -->
                  <label for="code">Trigger</label>
                  <br>
                  <nz-select [(ngModel)]="automated.code">
                    <nz-option nzValue="followcategory" nzLabel="New content upload"></nz-option>
                    <nz-option nzValue="welcome" nzLabel="User register"></nz-option>
                    <nz-option nzValue="payment" nzLabel="Payment success"></nz-option>
                    <nz-option nzValue="paymentError" nzLabel="Payment error"></nz-option>
                  </nz-select>
                  
                  
                  <!-- Segmentation? -->
                  <br>
                  <br>
                  <label nz-checkbox [(ngModel)]="automated.segment">Segment message</label>
                  <br>
                  <br>
                  
                  
                  <div [hidden]="!automated.segment">
                    <nz-select  nzMode="multiple"  [(ngModel)]='automated.segments_id'>
                        <nz-option *ngFor="let item of segments" [nzValue]="item.id" nzLabel="{{ item.name }}"></nz-option>
                    </nz-select>
                  </div>
                  
                </div>

                <div nz-col nzSpan="12" style="text-align: right;">
                  <nz-divider nzDashed nzText="Example as how notification will look"></nz-divider>
                  <br>
                  <!-- Exmple notification -->
                  <nz-card nzHoverable style="width:350px; height: 150px; margin: 0 auto; text-align: left;" [nzCover]="coverTemplate">
                    <nz-card-meta  nzTitle="{{ automated.name }}" nzDescription="{{ automated.message }}"></nz-card-meta>
                  </nz-card>
                  <ng-template #coverTemplate>
                    <img alt="example" style="width: 80px;height: 80px;float: left;position: absolute;top: 35px;left: 10px;" src="https://img.onesignal.com/permanent/51ce51fb-dab3-43a3-ab52-6e45cb5dfd68.png" />
                  </ng-template>
                  
                  <br>
                  <nz-divider nzDashed ></nz-divider>
                  <br>
    
                  <button nz-button nzType="default" (click)="save(automated,1)"><i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i> Save</button>
                  <button  *ngIf="automated.new" nz-button nzType="default" (click)="cancel(automated, automateds)"><i nz-icon nzType="close-circle" nzTheme="twotone"></i> Cancel</button>
                  <button  *ngIf="!automated.new" nz-button nzType="default" (click)="deleteAutomated(automated)"><i nz-icon [nzTwotoneColor]="'#eb2f96'" nzType="delete" nzTheme="twotone"></i> Delete</button>
                  <button nz-button nzType="default" (click)="sendNotification(automated)"><i nz-icon nzType="rocket" nzTheme="twotone"></i> Send</button>
        
                </div>
            </div>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <br>
    <br>
    <button nz-button nzType="default" (click)="createAutomated()"><i nz-icon nzType="plus-circle" nzTheme="twotone"></i> Create</button>

  </nz-tab>
  <nz-tab nzTitle="Segments">
    <nz-table #nzTable [nzData]="segments" nzTableLayout="fixed">
      <thead>
        <tr>
          <th nzWidth="60px"></th>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Created at</th>
          <th>Updated at</th>
          <th>Total Users</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let segment of segments">
          <tr>
            <td [nzExpand]="expandSet.has(segment.id)" (nzExpandChange)="onExpandChange(segment, $event)"></td>
            <td>{{ segment.id }}</td>
            <td>{{ segment.name }}</td>
            <td>{{ segment.description }}</td>
            <td>{{ segment.created_at }}</td>
            <td>{{ segment.updated_at }}</td>
            <td>{{ segment.total_users || "0" }}</td>
            <td><nz-badge nzStatus="{{ segment.status? 'success' : 'error' }}"></nz-badge>{{ segment.status? "Actived" : "Unactived" }}</td>
          </tr>
          <tr [nzExpand]="expandSet.has(segment.id)">
            <div nz-row [nzGutter]="16">

              <div nz-col nzSpan="6">
                <label for="name">Name of segment</label>
                <input nz-input placeholder="Enter a name" [(ngModel)]="segment.name" />
                <br>
                <br>
                <label for="description">Description</label>
                <input nz-input placeholder="Description" [(ngModel)]="segment.description" />
                <br>
                <br>
                <label for="status">Status Active/Inactive</label>
                <br>
                <nz-switch [(ngModel)]="segment.status"></nz-switch>
                <br>
                <br>
              </div>
            <div nz-col nzSpan="6">
             
            <label>Property</label>
            <nz-select (ngModelChange)="getSegmentFields($event)" [(ngModel)]="segment.segment_type_id"  >
              <nz-option *ngFor="let segment_type of segment_types" [nzValue]="segment_type.id" [nzLabel]="segment_type.name"></nz-option>
            </nz-select>
            <br>
            <br>
            <label>Attributes</label>
            <nz-select nzMode="multiple" [(ngModel)]="segment.segment_fields_value" >
              <nz-option *ngFor="let segment_field of segment_fields" [nzValue]="segment_field.value" [nzLabel]="segment_field.label"></nz-option>
            </nz-select>
             
            <br>
            <br>
            </div>
            <div nz-col nzSpan="12">
              
              <button nz-button nzType="default" (click)="saveSegment(segment)" ><i nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></i> Save</button>
              <button *ngIf="segment.new" nz-button nzType="default" (click)="cancel(segment, segments)" ><i nz-icon nzType="close-circle" nzTheme="twotone"></i> Cancel</button>
              <button *ngIf="!segment.new" nz-button nzType="default" (click)="deleteSegment(segment)"><i nz-icon [nzTwotoneColor]="'#eb2f96'" nzType="delete" nzTheme="twotone"></i> Delete</button>

            </div>
          </div>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <br>
    <br>
    <button nz-button nzType="default" (click)="createSegment()"><i nz-icon nzType="plus-circle" nzTheme="twotone"></i> Create</button>
  </nz-tab>
</nz-tabset>
