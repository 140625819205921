import { Component, OnInit } from '@angular/core';
import { Notification } from '../notification';
import { Segment } from '../segment';
import { NotificationService } from '../notification.service';
import { first } from 'rxjs/operators';
import { Segment_type } from '../segment_type';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notifications: Notification[];
  segments: Segment[];
  automateds: Notification[];
  token : string;
  segment_types : Segment_type[];

  selectedNotification: Notification;

  constructor(private notificationService: NotificationService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.login();
  }
  
  login(): void {

    this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.notificationService.login(params.username, params.password)
      .pipe(first())
      .subscribe(
        data => {
            console.log(data);
            this.getNotifications();
            this.getSegments();
            this.getSegmentTypes();
            this.getAutomateds();
          },
            error => {}
      );
    
    })

            

  }

  getNotifications(): void {
    this.notificationService.getNotifications()
          .subscribe(notifications => {
            this.notifications = notifications.map( notification => { notification.segment = notification.segment? true: false; return notification;});
          });

  }

  getSegments(): void {
    this.notificationService.getSegments()
          .subscribe(segments => {
            this.segments = segments
          });

  }

  getSegmentTypes(): void {
    this.notificationService.getSegmentTypes()
          .subscribe(segments_types => {
            this.segment_types = segments_types
          });

  }

  getAutomateds(): void {
    this.notificationService.getAutomateds()
          .subscribe(automateds => {
            this.automateds = automateds            
          });
  }

  onSelect(notification: Notification): void {
    this.selectedNotification = notification;
  }

}
