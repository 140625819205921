import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '../notification';
import { Segment } from '../segment';
import { Segment_type } from '../segment_type';
import { NotificationService } from '../notification.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';


@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.css'],
  styles: [
    `
      [nz-button] {
        margin-right: 8px;
        margin-bottom: 12px;
      }
      nz-select{
        min-width: 100% !important;
      }
      input,textarea{
        min-width: 100% !important;
      }
    `
  ]
})


export class NotificationItemComponent implements OnInit {
  @Input() notifications: [Notification];
  @Input() segments: [Segment];
  @Input() automateds: [Notification];
  @Input() segment_types: [Segment_type];
  
  listCountryCode = [];
  lastLogin = [];
  status = false;
  segment_fields = [];
  expandSet = new Set<number>();
  moment: moment.Moment;
  loading = false;


  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    
  }

  api(url: string){
    return this.notificationService.api()+url;
  }

  upload(info: { file: NzUploadFile },notification): void {
    console.log(info);
    if((typeof info.file.response.error === 'undefined')){
      notification.image = info.file.response.file;
      this.loading = false;
    }    
    else{
      this.getSwal('error',info.file.response.error);
    }
  }
    
    
  getSegmentFields(segment_type_id: number ): void {

    // Consume el servicio de la plataforma
    //this.segment_fields = [{ id: 1, label: "JM", value: "JM"},{ id: 2, label: "AR", value: "AR"},{ id: 2, label: "IL", value: "IL"}]
    this.getSwalProc('Getting attributes');
    this.notificationService.getSegmentFields(segment_type_id)
           .subscribe(segment_fields => { 
              this.segment_fields = segment_fields
              Swal.close()
            }
             );

  }

  getDate(notification){
    if(!notification.datetime_){
      return notification.datetime;
    }
    else{
      return notification.datetime_;
    }
  }
  
  transformDateOut(event,notification){
    if(event){
      let date = new Date();
      let dateOffet = date.getTimezoneOffset()/60;
      // To the save hour addition offset
      notification.datetime_ = moment(event, 'YYYY-MM-DD HH:mm:ss').toDate();
      notification.datetime = moment(event).add(dateOffet,'hours').format('YYYY-MM-DD HH:mm:ss');
    }
    else{
      return moment().toDate();
    }
  }


  onExpandChange(segment: Segment, checked: boolean): void {

    
    if (checked) {
      this.expandSet.add(segment.id);
      if(!segment.new){
        this.getSegmentFields(segment.segment_type_id)
      }
    } else {
      this.expandSet.delete(segment.id);
    }
  }

  onExpandChangeNotification(notification: Notification, checked: boolean): void {
    if (checked) {
      this.expandSet.add(notification.id);
    } else {
      this.expandSet.delete(notification.id);
    }
  }

  sendNotification(notification: Notification) : void{
    var _this = this;
    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Notification sending');
      _this.notificationService.postSend(notification)
        .subscribe(notification => {
          _this.getSwal('success','Notification sent');
        },  error => {
          _this.getSwal('success','Notification sent');
        });
    })
  }

  save(notification: Notification, auto) : void{

    var _this = this;
    var _notification = notification;

    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Saving notification');
      _this.notificationService.postNotification(notification, auto)
        .subscribe(notification => {
            if(auto){

              if(_notification.new == 1){
                let found = _this.automateds.findIndex( item => item.new == 1);
                _this.automateds[found] = notification;
                _this.automateds[found].new = 0;
              }
              else{
                let found = _this.automateds.findIndex( item => item.id == notification.id);
                _this.automateds[found] = notification;

              }

            }
            else{
              if(_notification.new == 1){
                let found = _this.notifications.findIndex( item => item.new == 1);
                _this.notifications[found] = notification;
                _this.notifications[found].new = 0;
              }
              else{
                let found = _this.notifications.findIndex( item => item.id == notification.id);
                _this.notifications[found] = notification;
              }
            }
            _this.getSwal('success','Notification saved');
        });
    });


  }

  createNotification(): void{
    var notification = {new: 1 ,id: 0, name: '', code: '', message: '', image: '', link: '',
    segment: false, datetime: '' , created_at:'' , updated_at:'', user_id:0, auto:'', segments_id: [], status: false };
    notification.name = '';
    this.onExpandChangeNotification(notification,true);

    this.notifications?.push(notification);
  }
  createAutomated(): void{
    var notification = {new: 1, id: 0, name: '', code: '', message: '', image: '', link: '',
    segment: false, datetime: '' , created_at:'' , updated_at:'', user_id:0, auto:'', segments_id: [], status: false };

    notification.name = '';
    this.onExpandChangeNotification(notification,true);

    this.automateds?.push(notification);

  }

  createSegment(): void{
    var segment = {new: 1, id: 0, name: '', created_at: '', updated_at: '', description: '', total_users: 0, status: '', segment_type_id: 0, user_id: 0, segment_fields_value: []};
    this.onExpandChange(segment,true);
    
    this.segments?.push(segment);

  }
  
  saveSegment(segment: Segment) : void{

    var _this = this;
    var _segment = segment;
    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Saving segment');
      _this.notificationService.postSegment(segment)
      .subscribe(segment => {

        if(_segment.new == 1){
          let found = _this.segments.findIndex( item => item.new == 1);
          _this.segments[found] = segment;
          _this.getSwal('success','Segment saved');
          _this.segments[found].new = 0;
        }
        else{
          let found = _this.segments.findIndex( item => item.id == segment.id);
          _this.segments[found] = segment;
          _this.getSwal('success','Segment saved');
        }
      
      });
    });
  }

  cancel(element: any, elements: any) : void{
    if(element.new == 1){
      var found = elements.findIndex( item => item.new == 1);
      elements.splice(found, 1);
    }
  }

  deleteNotification(notification: Notification) : void{

    var _this = this;
    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Removing notification');
        _this.notificationService.deleteNotification(notification)
        .subscribe(notification => {
          _this.getSwal('success','Notification removed');
          var found = _this.notifications.findIndex( item => item.id == notification.id);
          _this.notifications.splice(found, 1);
          });
    });
  }

  deleteAutomated(notification: Notification) : void{
    var _this = this;
    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Removing notification');
        _this.notificationService.deleteNotification(notification)
        .subscribe(notification => {
          _this.getSwal('success','Notification removed');
          var found = _this.automateds.findIndex( item => item.id == notification.id);
          _this.automateds.splice(found, 1);
          });
    });
  }
    
  deleteSegment(segment: Segment) : void{

    var _this = this;
    this.getSwalQuest(null,null,function(){
      _this.getSwalProc('Removing notification');
        _this.notificationService.deleteSegment(segment)
        .subscribe(segment => {
          _this.getSwal('success','Notification removed');
          var found = _this.segments.findIndex( item => item.id == segment.id);
          _this.segments.splice(found, 1);

        });  
    });
  }


  getSwalQuest(title: string, confirm: string, callback){
    Swal.fire({
      title: title? title : 'Are you sure?',
      backdrop: 'rgba(0,0,123,0.0)',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirm? confirm : 'Yes, do it!'
    }).then((result) => {
      if (result.value) {
        callback();
      }
    })
  }

  getSwal(type: string, message: string){
    Swal.close()
    Swal.fire({
      backdrop: 'rgba(0,0,123,0.0)',
      text: message,
      icon: type
    });
  }

  getSwalProc(message: string){
    Swal.fire({
      backdrop: 'rgba(0,0,123,0.0)',
      text: message,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    });
  }

      

}
