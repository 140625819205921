import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


//import { NOTIFICATIONS } from './mock-notifications';
//import { SEGMENTS } from './mock-segments';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Segment_type } from './segment_type';
import { Segment } from './segment';
import { Notification } from './notification';
import { Segment_fields } from './segment_fields';
import { GlobalVariables } from './common/global-variables';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

   API = 'https://notification.univtec.com/';
   //API = 'http://localhost/univ/uvod-notification/';


   api(){
     return this.API;
   }

  login(username: string, password: string): Observable<string>{ 

    return this.http.post<any>(this.API+'user/login',   {"username" : username , "password" : password })
      .pipe(map(user => {
          // Guardamos el token en local storage
          GlobalVariables.setUser(JSON.stringify(user));
          return user;
    }));
  
  }


  getSegmentFields(segment_type_id: number ): Observable<Segment_fields[]> {
    let user = JSON.parse(GlobalVariables.getUser());
    return this.http.post<Segment_fields[]>(this.API+'segment/fieldsApi/',{ segment_type_id: segment_type_id, token: user.data.token });
  }
  
  getNotifications(): Observable<Notification[]> {
    let user = JSON.parse(GlobalVariables.getUser());
    return this.http.post<Notification[]>(this.API+'notification/get', {token: user.data.token});
  }
  getSegments(): Observable<Segment[]> {
    //return of(SEGMENTS);
    let user = JSON.parse(GlobalVariables.getUser());
    return this.http.post<Segment[]>(this.API+'segment/get', {token: user.data.token});
  }
  getSegmentTypes(): Observable<Segment_type[]> {
    //return of(SEGMENTS);
    let user = JSON.parse(GlobalVariables.getUser());
    return this.http.post<Segment_type[]>(this.API+'segment_types/get',{ token: user.data.token});
  }
  getAutomateds(): Observable<Notification[]> {
    //return of(NOTIFICATIONS.filter(xx => xx.customs.length > 0));
    let user = JSON.parse(GlobalVariables.getUser());
    return this.http.post<Notification[]>(this.API+'automated/get',{ token: user.data.token});
  }

  postNotification( notification, auto ): Observable<Notification> {
    let user = JSON.parse(GlobalVariables.getUser());
    notification.token = user.data.token;
    notification.auto = auto? 1 : 0;
    return this.http.post<any>(this.API+'notification/post',notification)
  }

  postSegment( segment ): Observable<Segment> {
    let user = JSON.parse(GlobalVariables.getUser());
    segment.token = user.data.token;
    return this.http.post<any>(this.API+'segment/post',segment)
  }
  
  postSend( notification ): Observable<Notification> {
    let user = JSON.parse(GlobalVariables.getUser());
    notification.token = user.data.token;
    return this.http.post<any>(this.API+'notification/send',notification)
  }
  
  deleteNotification( notification ): Observable<Notification>{
    let user = JSON.parse(GlobalVariables.getUser());
    notification.token = user.data.token;
    return this.http.post<any>(this.API+'notification/delete',notification)
  }
  
  deleteSegment( segment ): Observable<Segment>{
    let user = JSON.parse(GlobalVariables.getUser());
    segment.token = user.data.token;
    return this.http.post<any>(this.API+'segment/delete',segment)
  }
  
  /*
  getSegmentsField(): Observable<Segment_fields[]> {      
    return this.http.get<Segment_fields[]>(this.API+'segment_fields/get');
  }
  */
  constructor( private http: HttpClient) { }
}
