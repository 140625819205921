export class GlobalVariables {

    static user: string;

    static setUser(user: string) {
        this.user = user;
    }

    static getUser() : string {
        return this.user;
    }


}